<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Clientes</h4>
                  <h1>Usuarios</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <span class="p-input-icon-left" style="width: 100%;" c>
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar..." class="search" />
                  </span>
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <MultiSelect class="input table-columns-selector" :modelValue="selectedColumns" :options="columns" @update:modelValue="onToggle" placeholder="Seleccionar Columnas" />
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <ion-buttons class="options-nav">
                    <ion-button @click="exportCSV($event)">
                      <ion-icon color="dark" slot="icon-only" :icon="cloudDownloadOutline"></ion-icon>
                    </ion-button>
                  </ion-buttons>
                </ion-col>
              </ion-row>
            </ion-grid>
          </section>

        <section id="lists" class="mb-lg fullwidth-mobile">
            <DataTable 
                :value="elements"
                :totalRecords="totalRecords"
                ref="dt"
                :lazy="false"
                v-model:selection="selectedElements"
                dataKey="objectID"
                removableSort
                responsiveLayout="scroll"
                :paginator="true"
                :rows="25"
                v-model:filters="filters"
                :loading="loading"
                :reorderableColumns="true"
                :globalFilterFields="['name', 'first', 'second', 'desserts', 'drinks', 'uid']"
                filterDisplay="menu"
                :rowsPerPageOptions="[25,50,100]"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando desde {first} a {last} de {totalRecords} elementos"
              >
                <template #empty>
                    No hay resultados
                </template>
                <template #loading>
                    Cargando datos...
                </template>
                <Column selectionMode="multiple"></Column>

                <!-- Secuecia Normal -->
                <Column v-if="selectedColumns.includes('Fecha Registro')" field="dateCreated" header="Fecha" dataType="date" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fechaDia(slotProps.data.dateCreated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                  </template>
                </Column>
                
                <Column v-if="selectedColumns.includes('Nombre')" field="firstName" header="Nombre" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span class="table-field table-primary-field"><span v-if="slotProps.data.firstName">{{ slotProps.data.firstName.toLowerCase() }}</span>, <span v-if="slotProps.data.lastName">{{ slotProps.data.lastName.toLowerCase() }}</span></span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Email')" field="email" header="Email" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span>{{ slotProps.data.email }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Teléfono')" field="phone" header="Teléfono" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.phone }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Código Postal')" field="zipCode" header="Código Postal" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.zipCode }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Código Postal"/>
                  </template>
                </Column>


                <!-- Datos del Cliente -->
                <Column v-if="selectedColumns.includes('UID')" field="customer.uid" header="UID" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.customer" class="table-field">{{ slotProps.data.customer.uid }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Dirección')" field="address" header="Direccíon" >
                  <template #body="slotProps">
                        <span class="table-field">{{ slotProps.data.address }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Ciudad')" field="city" header="Ciudad" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.city }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Ciudad"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Sexo')" field="sex" header="Sexo" >
                  <template #body="slotProps">
                        <span class="table-field" v-if="slotProps.data.sex === 'm'">Masculino</span>
                        <span class="table-field" v-if="slotProps.data.sex === 'f'">Femenino</span>
                        <span class="table-field" v-if="slotProps.data.sex === 'o'">Otro</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Nacimiento')" field="birthDate" header="Nacimiento" dataType="date" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field table-field-date">{{ fechaDia(slotProps.data.birthDate) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yy" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Ult. Login')" field="lastLogin" header="Ult. Login" dataType="date" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field table-field-date">{{ fechaDia(slotProps.data.lastLogin) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yy" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Registro')" field="provider" header="Registro">
                  <template #body="slotProps">
                    <span class="table-field" v-if="slotProps.data.provider === 'google.com'">Google</span>
                    <span class="table-field" v-if="slotProps.data.provider === 'facebook.com'">Facebook</span>
                    <span class="table-field" v-if="slotProps.data.provider === 'apple.com'">Apple</span>
                    <span class="table-field" v-if="slotProps.data.provider === 'email'">Email</span>
                  </template>
                </Column>

                <!-- Notificaciones --> 
                <Column v-if="selectedColumns.includes('Notificaciones')" field="notifications" header="Notificaciones" >
                  <template #body="slotProps">
                    <span class="table-field"  v-if="slotProps.data.notifications"><span v-if="slotProps.data.notifications.email">Email,</span> <span v-if="slotProps.data.notifications.mobile">Push</span></span>
                  </template>
                </Column>

                <!-- Acciones -->
                <Column field="actions" style="width: 122px;">
                  <template #body="slotProps">
                      <ion-button shape="round" fill="outline" color="dark" @click="openDetails({component: 'clients-user', data: slotProps.data})">
                        <ion-label>Detalles</ion-label>
                      </ion-button>
                  </template>
                </Column>
            </DataTable>               
        </section>
       </div>

      <Footer></Footer>
  </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonButton, IonButtons, IonLabel } from '@ionic/vue';

import { trashOutline, cloudDownloadOutline, addCircleOutline, archiveOutline,optionsOutline, ellipsisVerticalOutline, eyeOutline, createOutline, printOutline, arrowUpOutline, closeCircleOutline, funnelOutline, funnel, cloudUploadOutline, openOutline, statsChartOutline } from 'ionicons/icons';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';

import { FilterMatchMode, FilterOperator } from 'primevue/api';

import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';
import Header from '@/components/Header.vue';

export default defineComponent({
  name: 'Users',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    Footer,
    DataTable,
    Column,
    InputText,
    IonButtons, 
    IonLabel,
    IonButton,
    Calendar,
    Header,
    MultiSelect
  },
    data() {
      return {
        search: '',
        selectedElements: [],
        filters: null,
        openFilters: false,
        loading: true,
        lazyParams: null,
        totalRecords: 0,
        selectedColumns: null,
        columns: [
          'UID',
          'Fecha Registro',
          'Ult. Login',
        
          'Nombre',
          'Sexo',
          'Teléfono',
          'Email',
          'Nacimiento',

          'Dirección',
          'Ciudad',
          'Código Postal',

          'Registro',
          'Notificaciones'
        ],
      };
    },
    computed: {
      ...mapState('clients', {
        elements: state => state.users,
      }),

      ...mapState(['evolbeUser', 'preferences'])
    },
    methods: {
      ...mapActions('clients',['getUsers', 'deleteUser', 'updateUser']),
      ...mapActions(['openDetails']),

      // Initialization
      initFilters() {
          this.selectedColumns = [
            'Fecha Registro',
            'Nombre',
            'Teléfono',
            'Email',
          ];

          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
              'dateCreated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'lastLogin': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'birthDate': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'city': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
              'zipCode': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
          }

          this.lazyParams = {
              first: 0,
              rows: 0,
              sortField: null,
              sortOrder: null,
              filters: this.filters
          }
      },

      // Table
      onPage(event) {
        this.lazyParams = event;
        this.loadLazyData();
      },

      onToggle(value) {
        this.selectedColumns = this.columns.filter(col => value.includes(col));
      },

      onSort(event) {
        this.lazyParams = event;
        this.loadLazyData();
      },

      exportCSV() {
          this.$refs.dt.exportCSV();
      },

      fecha(date){
        return moment(date).format('DD/MM/YYYY-HH:mm');
      },

      fechaHora(date){
        return moment(date).format('HH:mm');
      },

      fechaDia(date){
        if(date){
          const newDate = Number(date);
          return moment(newDate).format('DD/MM/YYYY');
        } else {
          return null;
        }

      },

      async loadLazyData() {
        this.loading = true;

        await this.getUsers(this.lazyEvent).then(() => {
          this.loading = false;
          this.totalRecords = this.elements.length;

          this.elements.forEach(element => element.dateCreated = new Date(Number(element.dateCreated)));
          this.elements.forEach(element => element.lastLogin = new Date(Number(element.lastLogin)));
          this.elements.forEach(element => element.birthDate = new Date(Number(element.birthDate)));
        })
      },
    },
    created(){
      this.initFilters();
      this.loadLazyData();
      moment.locale('es');
    },
    setup() {
      return { trashOutline, cloudDownloadOutline, eyeOutline, archiveOutline, ellipsisVerticalOutline, addCircleOutline, optionsOutline, createOutline, printOutline, closeCircleOutline, arrowUpOutline, funnelOutline, funnel, cloudUploadOutline, openOutline, statsChartOutline };
    }
});
</script>

<style scoped>
  .table-field{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
  }

  .table-primary-field{
    font-weight: bold;
  }

</style>